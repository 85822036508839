<template>
  <base-section
    id="features"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="4"
          >
            <base-info-card
              align="center"
              dark
              :frame="true"
              v-bind="card"
              :features="true"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          icon: 'mdi-zodiac-libra',
          title: 'Astrologie & Psychologie',
          text: '',
          id: 'astropsychology',
        },
        {
          icon: 'mdi-circle-double',
          title: 'Hypnosetherapie',
          text: '',
          id: 'hynosetherapie',
        },
        {
          icon: 'mdi-account-convert',
          title: 'Kurse & Seminare',
          text: '',
          id: 'seminare',
        },
        /* {
          icon: 'mdi-cash',
          title: 'Preise',
          text: '',
        }, */
      ],
    }),
  }
</script>
